


import React/*, { useEffect }*/ from "react";
const Askedquest = () => {

    /*  const paths = window
          .location
          .pathname
          .split("/")
          .filter(path => path !== "");
      const pathName = paths[paths.length - 1];
  
      useEffect(() => {
          alert(pathName)
          if (pathName === 'asked-quest') { alert('Not Found') }
  
  
      }, [pathName])*/

    document.title = ('الأسئلة المتكررة');
    return (
        <>


            <div className='px-11 mb-16'>

                <div className='font-bold text-2xl mb-8 mt-3 text-center' >الأسئلة المتكررة</div>

                <div>
                    <img className="h-60 w-full mb-8 lgg:px-60 lgg:py-6 smm:px-11 text-center" decoding="async" src="https://i0.wp.com/www.soq-dz.com/wp-content/uploads/2022/05/faq-soq-dz.jpg" alt="" />



                    <h4 ><strong>متى يصل لي الطلب؟</strong></h4>

                    <br />

                    <p>تعتمد على شركة الشحن التي تم إختيارها والمسؤول عن التوصيل، نحن نوفر مجموعة من المسؤولين عن الشحن بمجموعة من المدن الكبرى، وعادة تأخد من يوم إلى 3 أيام من الطلب وتأكيده.</p>
                    <br />



                    <h4><strong>هل يوجد توصيل خارج البلد؟</strong></h4>
                    <br />



                    <p>حالياً شركات الشحن المتعاقد معها تقوم بالتوصيل داخل البلد ومجموعة من المدن المحددة في صفحة التوصيل يتم عرض كافة المدن التي يصل لها مندوب شركة الشحن</p>



                    <p>يمككك طلب التوصيل خارج البلد من فريق المتجر عبر الواتساب للقيام بمعلومات وزن المنتج وتسعير وكلاء الشحن الدوليين الغير مسجلين حالياً في المتجر .</p>


                    <br />
                    <h4 ><strong>في حالة وجود عيب مصنعي في المنتج؟</strong></h4>

                    <br />

                    <p>يجب الرجوع وقراءة سياسة الإسترجاع لمعرفة شروط الإسترجاع والإستبدال، وفي حالة وجود عيب مصنعي تم الإتفاق على إرجاعة يتم إحتساب قيمة الشحن للشركة وكذلك إعادة الشحن على العميل أما قيمة المنتج فلا يتم إحتسابة على العميل حسب سياسية الإسترجاع.</p>

                    <br />

                    <h4 ><strong>أريد طلب كميات كبيرة هل يوجد خصم؟</strong></h4>

                    <br />

                    <p>يوفر المتجر خدمة توصيل الكميات الكبيرة في حالة الطلب وهنالك خصم يصل من 10-20٪ على بعض المنتجات يمكن التواصل المباشر معنا عبر صفحة إتصل بنا والإستفسار عن الكميات والخصم</p>
                    <br />  <br />
                </div>
            </div>
        </>
    )
}
export default Askedquest;



