import React, { createContext} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductDetail from './ProductDetail'
import Matjar from './Matjar';
import NavBar from "./NavBar";
import Footer from "./footer";
import TermsConditionss from './footer_links/Terms_conditionss';
import ReturnPolicy from './footer_links/Return_policy';
import Confidentiality from './footer_links/Confidentiality';
import Howtopy from './footer_links/HowToPay';
import Shippingdelivery from './footer_links/ShippingDelivery';
import Contactus from './footer_links/Contact-us';
import Aboutus from './footer_links/About_us';
import Askedquest from './footer_links/AskedQuest';
import Notfound from './NotFound';
// import Addproduct from './admin/Add_product';
// import ProductsList from './admin/Products_list';
import ThankPage from './ThankPage';
import Orders from './admin/Orders';
import Dasha from './admin/dash';
import SignIn from "./admin/dash/auth/layout"
// import Dash from './admin/Dash';
// import Uploadimga from './admin/Uploadimga';


export const richetexta = createContext();
const Routta = () => {
    // const [value, setValue] = useState('');
    // const updateValue = (data) => {
    //     setValue(data)
    // }

    return (

        <BrowserRouter>
            <NavBar />
            <Routes>

                <Route path='/' element={<Matjar />} />
                <Route path='/product/*' element={<ProductDetail />} />
                <Route path='/terms_conditions' element={<TermsConditionss />} />
                <Route path='/return_policy' element={<ReturnPolicy />} />
                <Route path='/confidentiality' element={<Confidentiality />} />
                <Route path='/how-to-pay' element={<Howtopy />} />
                <Route path='/shipping-delivery' element={<Shippingdelivery />} />
                <Route path='/contact-us' element={<Contactus />} />
                <Route path='/about-us' element={<Aboutus />} />
                <Route path='/asked-quest/*' element={<Askedquest />} />
                <Route path='*' element={<Notfound />} />
                <Route path='/admin/*' element={<Dasha />} />
                <Route path='/auth/*' element={<SignIn />} />

                {/* <Route path='/admin' element={<richetexta.Provider value={{ value, updateValue }}><Addproduct /></richetexta.Provider>} /> */}
                {/* <Route path='/admin/addproduct' element={<richetexta.Provider value={{ value, updateValue }}><Addproduct /> </richetexta.Provider>} /> */}
                {/* <Route path='/admin/products' element={<ProductsList />} /> */}
                {/* <Route path='/login' element={<Login />} /> */}
                {/* <Route path='/up' element={<Uploadimga />} /> */}
                <Route path='/thank_page' element={<ThankPage />} />
                <Route path='/admin/orders' element={<Orders />} />


            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default Routta;