/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "../../../icons/DashIcon"
// chakra imports

export function SidebarLinks({ routes }) {
  // Chakra color mode
  let location = useLocation();

  // const { routes } = props.routes;
  // const { open } = props.open;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        let rounta = (route.path.endsWith('*')) ? route.path.slice(0, -1) : route.path;

        return (
          <Link key={index} to={route.layout + "/" + rounta}>
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${activeRoute(rounta) === true
                    ? "font-bold text-green-400 dark:text-white"
                    : "font-medium text-gray-600"
                    }`}
                >

                  {activeRoute(rounta) === true ?
                    route.active_icon ? route.active_icon : <DashIcon /> + " "
                    : route.icon ? route.icon : <DashIcon /> + " "}
                </span>
                <p
                  className={`leading-1 flex ms-4 ${activeRoute(rounta) === true
                    ? "font-bold text-xl text-green-400 dark:text-white"
                    : "font-medium text-gray-600 hover:text-2xl"
                    }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(rounta) ? (
                <div class="absolute top-px h-9 w-1 rounded-lg bg-green-400 end-0 dark:bg-white" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
