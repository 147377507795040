import React from "react";

const Contactus = () => {
    document.title = ('اتصل بنا');
    return (
        <>
            <div>
                <ul className='px-11 text-center'>
                    <li className='font-bold text-2xl mb-8 mt-3 text-center' >اتصل بنا</li>

                    <li className="font-semibold text-lg">
                        <p>
                            - للتواصل معنا عبر البريد الإلكتروني:  contact@ecomdz.shop <br />

                            - للتواصل معنا عبر الهاتف:********* 0672333762 ************* <br /><br />


                        </p>
                    </li>
                    <li className="text-red-500 font-bold text-xl">
                        سنكون سعداء بالتواصل معكم والإجابة عن أسئلتكم. <br /><br />
                    </li>
                </ul>
            </div>
        </>
    )
}
export default Contactus;