import React from 'react';

const Shippingdelivery = () => {
    document.title = ('الشحن والتسليم');
    return (
        <>
            <div>
                <ul className='px-11'>
                    <li className='font-bold text-2xl mb-8 mt-3 text-center' >الشحن والتسليم</li>

                    <li>بعد تأكيد عملية الشراء، نقوم بشحن وإرسال المنتج عبر الطريقة التي قمتم بإختيارها، إما عبر مسؤول الشحن الخاص بنا أو عبر خدمة أمانة إكسبريس.<br /><br />

                    </li>
                    <li className='font-bold text-lg mb-8' >طرق الشحن:</li>

                    <li>
                        أمانة إكسبريس: خدمة تضمن لكم تسليم الإرساليات إلى العنوان المطلوب في مدة تتراوح بين 3 أيام و 7 أيام نحوالإتجاهات الرئيسية.<br />

                        مسؤول الشحن: متجرنا يتعاقد مع مجموعة من مسؤولي الشحن بمجموعة من المدن  يقومون بإيصال المنتجات في مدة تتراوح بين يوم و3 أيام
                        <br />
                        <br />
                    </li>

                </ul>
            </div >
        </>
    )
}
export default Shippingdelivery;