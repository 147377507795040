/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./componentsrtl/Links";
import routes from "../../routes";
import { Link } from 'react-router-dom';

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "" : "hidden"
        }`}
    // open ? "translate-x-0" : "translate-x-96"
    >
      <span
        className="absolute top-4 block cursor-pointer end-4 "
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-green-800 ms-1 dark:text-white">
          <Link className=' hover:text-green-500 hover:text-3xl' to="/">   ECOM <span class="font-medium">DZ</span></Link>

        </div>
      </div>
      <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} open={open} />
      </ul>

    </div>
  );
};

export default Sidebar;
