import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "./components/navbara";
import Sidebar from "./components/sidebar/RTL";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import routes from "./routes";
import Cookies from 'js-cookie';

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const navigate = useNavigate();
  const token = Cookies.get('token');
  const paths = window
    .location
    .pathname
    .split("/")
    .filter(path => path !== "");

  const active_url = paths[paths.length - 1];
  useEffect(() => {

    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true))
    if (Cookies.get('dark')) { document.body.classList.add("dark"); Cookies.set('dark', 'false') }
    else { document.body.classList.remove("dark"); }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      //  alert('gggggg')
      event.returnValue = ''; // This line is required for some browsers to show the confirmation dialog

    };
    if (active_url === 'add_new') {

      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };


  }, [active_url]);
  useEffect(() => {
    if (token) {
      getActiveRoute(routes);
    }
    else {
      navigate('/auth/', { replace: true });
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  function navbar_state() {
    open ? setOpen(false) : setOpen(true)
  }

  function Logout() {
    Cookies.remove('token');
    Cookies.remove('session_name');
    navigate('/auth/', { replace: true });
  }

  // document.documentElement.dir = "rtl";

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="viewport" content={'width=1024'} />
        </Helmet>
      </HelmetProvider>

      <div className="flex h-full w-full">
        <Sidebar open={open} onClose={() => setOpen(false)} />
        {/* Navbar & Main Content */}
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          {/* Main Content */}
          <main
            // className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
            className={`mx-[12px] h-full flex-none transition-all md:pe-2 xl:mr-[313px]`}
          >
            {/* Routes */}
            <div className="h-full">
              <Navbar
                onOpenSidenav={() => navbar_state()}
                logout={() => Logout()}
                logoText={"Horizon UI Tailwind React"}
                brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                opena={open}
                {...rest}
              />
              <div className={`pt-5s mx-auto mb-auto h-full  min-h-[84vh] p-2 md:pr-2 ${open ? "xll:ps-[210px] mdd:ps-[80px]" : ""}`}>
                <Routes>
                  {getRoutes(routes)}

                  <Route
                    path="/"
                    element={<Navigate to="/admin/order" replace />}
                  />
                </Routes>
              </div>
              <div className="p-3">
                {/* <Footer /> */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
