import React from 'react';
const Howtopy = () => {
    document.title = ('طرق الدفع');
    return (
        <>
            <div>
                <ul className='px-11'>
                    <li className='font-bold text-2xl mb-8 mt-3 text-center' >طرق الدفع</li>
                    <li className='font-bold text-lg mb-8' >الدفع نقداً عند الاستلام (COD)</li>
                    <li>
                        الدفع عند الاستلام (Cash On Delivery) هي إحدى طرق الدفع المتاحة على متجرنا، والدفع عند الاستلام يعني أن المتسوق يمكنه التسوق عبر متجرنا إلكتروني واختيار المنتج الذي يرغب فيه ومن ثم يقوم بإجراء الطلب واختيار طريقة الدفع عند الاستلام وهو ما يعني أن عملية الدفع تؤجل حتى استلام العميل للمنتج الذي قام بطلبه إلكترونيًا.<br />

                        وسنقوم بإرسال المنتج إلى المكان المتفق عليه (المدينة أو الحي أو المنزل أو مكان اخر)، وبعدها يتم الدفع.<br /><br />
                    </li>
                    <li className='font-bold text-lg mb-8' >الدفع عبر تحويل بنكي</li>
                    <li>
                        الدفع عبر تحويل بنكي هو أحد طرق الدفع المتاحة على متجرنا، والدفع يتم عبر تحويل المبلغ المتفق عليه إلى حسابنا البنكي، أو عبر حوالة بنكية، او عبر إحدى وكالت تحويل الأموال بالإسم المتفق عليه، وبعدها يتم إرسال المنتج إلى المكان المتفق عليه.<br /><br />
                    </li>
                    <li className='font-bold text-lg mb-8' >الدفع عبر PayPal</li>
                    <li>
                        الدفع عبر تحويل موقع PayPal هو أحد الطرق المتاحة على متجرنا، والدفع يتم عبر تحويل المبلغ المتفق عليه إلى حسابنا على موقع PayPal ، وبعدها يتم إرسال المنتج إلى المكان المتفق عليه.<br /><br /><br />
                    </li>
                </ul>
            </div>
        </>
    )
}
export default Howtopy;