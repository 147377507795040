import Footer from "./components/FooterAuthDefault";
import authImg from "../../../../../../assets/images/auth/auth.png"  //"assets/img/auth/auth.png";
import {/* Link,*/ Routes, Route, Navigate } from "react-router-dom";
import routes from "../../routes";
import FixedPlugin from "./components/FixedPlugin";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full ps-8 min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 mdlog:max-w-[75%] lglog:h-screen lglog:max-w-[1013px] lglog:px-8 lglog:pt-0 xllog:h-[100vh] xllog:max-w-[1383px] xllog:px-0 xllog:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 mdlog:pr-0 mdlog:pl-12 lglog:max-w-[48%] lglog:pl-0 xllog:max-w-full">
                {/* <Link to="/admin" className="mt-0 w-max lglog:pt-10">
                  <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                        fill="#A3AED0"
                      />
                    </svg>
                    <p className="ml-3 text-sm text-gray-600">
                      Back to Dashboard
                    </p>
                  </div>
                </Link> */}
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
                <div className="absolute left-0 hidden h-full min-h-screen mdlog:block lglog:w-[49vw] 2xllog:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lglog:rounded-br-[120px] xllog:rounded-br-[200px]"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
