import React from 'react';
const TermsConditionss = () => {
    document.title = ('شروط الاستخدام');
    return (
        <>
            <div>
                <ul className='px-11'>
                    <li className='font-bold text-2xl mb-8 mt-3 text-center' >شروط الاستخدام</li>
                    <li className='font-bold text-lg mb-8' >المقدمة</li>

                    <li className='text-start mb-8'>
                        <p>تنطبق شروط الاستخدام على الموقع وعلى جميع أقسامها وفروعها ومواقع الإنترنت التابعة لها التي تُشير إلى هذه الشروط والأحكام كمرجعٍ لها.</p>
                        <br />
                        <p>   عند زيارة الموقع، يقر العميل موافقته على الشروط والأحكام الحالية. وإن كنت لا توافق عليها، فعليك عدم استخدام هذا الموقع. ويحتفظ القائمون على الموقع بالحق في تغيير أجزاء من شروط الاستخدام والأحكام أو تعديلها أو إضافة معلومات إليها، أو إزالتها في أي وقت. وتصبح التغييرات سارية النفاذ عندما يتم نشرها على الموقع دون سابق إشعار. ويُرجى مراجعة شروط الاستخدام والأحكام بانتظام للاطلاع على أي تحديثات. ويشكِّل استخدامك المستمر للموقع ــ بعد نشر التغييرات الحادثة في هذه الشروط والأحكام الخاصة بالاستخدام ــ موافقتك التامة على تلك التغييرات.</p>
                    </li>
                    <li className='font-bold text-lg mb-8' >استخدام الموقع</li>
                    <li>
                        <p>لزيارة هذا الموقع، يجب ألا يقل عمرك عن 18 عامًا أو أن تزور الموقع تحت إشراف أحد الوالدين أو الوصي القانوني.</p>
                        <br />
                        <p>ونمنحك ترخيصًا غير قابل للتحويل أو الإلغاء لكي تستخدم الموقع بموجب الشروط والأحكام المحدَّدة. وتكمن غاية هذا الترخيص في التسوُّق لشراء سلعٍ شخصية تُباع على الموقع. ويُحظر الاستخدام لأغراض تجارية أو الاستخدام باسم أي طرف ثالث، باستثناء ما تمَّ السماح به من قبلنا بوضوح وشفافية سلفًا. ويؤدِّي أي خرق لهذه الشروط والأحكام إلى إلغاء فوري للترخيص الممنوح في هذه الفقرة من دون أي سابق إنذار لك.</p>  <br />
                        <p>يتم عرض المحتوى الظاهر على هذا الموقع لأغراض إعلامية فقط. وتعود التوضيحات التي تخُص المنتجات والتي أُعرب عنها في هذا الموقع إلى البائعين أنفسهم وليس لنا فيها أي شأن. وترجع التعليقات أو الآراء الواردة على هذا الموقع إلى الجهة التي نشرتها وبالتالي لا تعكس آراءنا.</p>  <br />
                        <p>تتطلب بعض الخدمات والميزات ذات الصلة التي قد تكون متوفرة على الموقع التسجيل أو الاشتراك فيها. وباختيارك التسجيل أو الاشتراك في أيّ من هذه الخدمات أو الميزات ذات الصلة، فإنَّك توافق على تقديم معلومات دقيقة وحالية عن نفسك وعلى تحديثها في الوقت المناسب إذا طرأت أيّة تغييرات عليها. ويتحمَّل كُلّ مستخدم للموقع ــ وحده ــ مسؤولية حفظ كلمات السر وغيرها من أساليب تحديد حساب الاستخدام بطريقة سليمة. وتقع كامل المسؤولية على عاتق صاحب الحساب بالنسبة فيما يخص جميع الأنشطة التي تحدث باستخدام كلمة السر الخاصة به أو حسابه. بالإضافة إلى ذلك، عليك تبليغنا عن أي استخدام غير مصرَّح به لكلمة السر أو حسابك. ولا يُعد الموقع مسؤولاً في أي حال من الأحوال، بشكل مباشر أو غير مباشرٍ أو بأي شكل من الأشكال، عن أيّة خسارة أو أضرار من أي نوع، قد تنتُج عن تعثرك في الامتثال لهذا القِسم أو تتصل به.</p>  <br />
                        <p>وأثناء عملية التسجيل، يوافق العميل على تلقِّي رسائل إلكترونية ترويجية من قِبل الموقع. ويمكنك، في وقت لاحق، إلغاء هذا الخيار وعدم تلقي أي رسائل إلكترونية ترويجية من خلال الضغط على الرابط الموجود في الجزء السفلي من أية رسالة إلكترونية ترويجية.</p>  <br />
                    </li>
                    <li className='font-bold text-lg mb-8' >مشاركات المستخدم</li>
                    <li><p>إنّ كلّ مشاركاتك على الموقع و/أو ما تقدمه لنا، بما في ذلك ــ على سبيل المثال وليس الحصرــ الأسئلة والانتقادات والتعليقات والاقتراحات (مجتمعين في لفظة “المشاركات”) تصبح ملكنا الوحيد والحصري، ولا تعود بأي حالٍ من الأحوال ملكاً لك. وفضلاً عن الحقوق التي تنطبق على أي نوع من المشاركات، وبمجرَّد أنَّك تشاركنا بتعليقاتك أو انتقاداتك على الموقع، فإنَّك تمنحنا أيضاً حقّ استخدام الاسم الذي تعرضه والمرتبط مباشرةً بهذا النقد أو التعليق أو أي محتوى آخر. ولا يحق لك استخدام عنوان مزيَّف للبريد الإلكتروني أو الادِّعاء بأنّك شخصٌ آخر أو محاولة تضليلنا أو أي طرف ثالث فيما يتعلَّق بأصالة وموثوقيّة أي من المشاركات. ويجوز لنا حذف أيّ من المشاركات أو تعديلها، غير أننا لسنا ملزمين بذلك.</p> <br /></li>
                    <li className='font-bold text-lg mb-8' >الموافقة على الطلبات وتفاصيل الأسعار</li>
                    <li>
                        <p>
                            يُرجى الملاحظة أنّه في بعض الحالات، قد لا تتم الموافقة على طلب ما لعدَّة أسباب. ويحتفظ القائمون على الموقع بالحقّ في رفض أو إلغاء أي طلب لأي سببٍ من الأسباب وفي أي وقت. وقبل أن نوافق على الطلب، قد نطلب منك تقديم معلومات إضافية أو بيانات أخرى للتحقق من شيء ما، بما في ذلك ــ على سبيل المثال وليس الحصر، رقم الهاتف والعنوان.
                        </p><br />
                        <p>
                            ونحرص على توفير أدق المعلومات المتعلقة بالأسعار لجميع المستخدمين الذين يزورون الموقع. إلّا أنَّ الأخطاء قد تَرِدُ في بعض الأحيان، كالحالات التي لا يتمّ فيها تحديد سعر المنتج بشكلٍ صحيحٍ على الموقع. ومن ثمّ، فنحتفظ بالحقّ في رفض أو إلغاء أي طلب من الطلبات. وفي حالة ما إذا لم يتم تحديد سعر المنتج بشكلٍ صحيح، فيحق لنا، وفقًا لتقديرنا الخاص، الاتصال بك للحصول على تعليماتٍ أو إلغاء طلبك وإبلاغك بهذا الإلغاء. ولنا الحق في رفض أو إلغاء أي طلبات سواء تمّ تأكيدها أو لا وبعد إضافة الرسوم على بطاقة الائتمان.
                        </p><br />
                    </li>

                    <li className='font-bold text-lg mb-8' >العلامات التجارية وحقوق النشر</li>
                    <li>
                        <p>إنَّ جميع حقوق الملكية الفكرية، سواء كانت مسجلَّة أو غير مسجلَّة، في الموقع، وجميع معلومات المحتوى والتصميمات الواردة على الموقع، تُعتبر ملكاً لنا، بما في ذلك على سبيل المثال وليس الحصر، النصوص والرسوم والبرامج والصور والفيديو والموسيقى والصوت، واختيارها وتنسيقها، فضلاً عن جميع مصنّفات البرمجيات ورموز المصادر والبرامج الرئيسية. وإنّ كلّ محتويات الموقع محميةٌ أيضًا بموجب حقوق النشر مجتمعة في صورة عمل واحد. جميع الحقوق محفوظة.

                        </p><br />
                    </li>

                    <li className='font-bold text-lg mb-8' >القانون السائد والهيئات القضائية</li>
                    <li><p>تُفسَّر هذه الشروط والأحكام وتُطبَّق بموجب القوانين السارية في البلد. وبموجب ذلك، يوافق كل طرف على المثول أمام هيئات المحاكم القضائية في البلد والتنازل عن أية اعتراضات تتعلق بالمكان.</p><br /></li>
                    <li className='font-bold text-lg mb-8' >إلغاء الموافقة</li>

                    <li><p>
                        بالإضافة إلى أي أحكام قانونية أو إجراءات الإنصاف القضائي، يجوز لنا، فورًا ومن دون سابق إنذار لك، إنهاء هذه الشروط والأحكام أو نلغي أيا من حقوقك الممنوحة أو جميعها بموجب الشروط والأحكام. وفي أي حالة لإنهاء هذا التعاقد، يتعيّن عليك التوقّف فورًا عن زيارة الموقع واستخدامه، وفضلاً عن إصدار أحكام قانونية أو إجراءات منصفة قضائيًا، قد نُلغي فورًا جميع كلمات السرّ أو غيرها من أساليب تعريف الحساب الممنوحة لك، ونرفض أي زيارة أو استخدام لهذا الموقع كلّيًا أو جزئيًا. ولن يؤثِّر أي إبطال لهذا الاتفاق في جميع الحقوق والالتزامات (بما في ذلك ولكن ليس على سبيل الحصر، التزامات الدفع) الخاصة بالأطراف والصادرة قبل تاريخ إنهاء التعاقد. ومن ثمّ، فتوافق على أنَّ القائمين على الموقع لن يتحملوا المسؤولية عنك أو عن أيّ فرد آخر نتيجة لتعليق الخدمة أو إنهائها. وإذا كنتَ غير راضٍ عن هذا الموقع أو عن أي من الشروط أو الأحكام أو النظام أو السياسات أو المبادئ التوجيهية أو الممارسات الخاصة بمتجر في كيفيّة إدارة المتجر، فالإجراء الوحيد والحصري الذي عليك اتخاذه عندئذٍ هو التوقُّف عن استخدام الموقع.
                    </p> <br /></li>

                </ul>


            </div>

        </>
    )

}
export default TermsConditionss;