import React from 'react';
const ReturnPolicy = () => {
    document.title = ('سياسة الاستبدال والاسترجاع');
    return (
        <>
            <div>
                <p>
                    <ul className='px-11'>
                        <li className='font-bold text-xl mb-8 mt-3 text-center' >سياسة الاستبدال والاسترجاع</li>
                        {/* <li className='font-bold text-lg mb-8' >سياسة الاستبدال والاسترجاع: </li> */}
                        <li className='text-lg'><p>- الإستبدال والإسترجاع حق مضمون كل عملائنا وهو يشمل جميع المنتجات التي نعرضها على متجرنا.<br /><br />
                            - جميع المنتجات المعروضة على متجرنا قابلة لسياسة الإستبدال والإسترجع وفق الشروط والأحكام المنصوص عليها في هذه الصحفة.<br /><br />
                            - يمكن الإرجاع أو الإستبدال إذا كان المنتج بنفس حالته الأصلية عند الشراء ومغلفا بالغلاف الأصلي.<br /><br />
                            - الاسترجاع خلال ثلاثة (3) أيام والاستبدال خلال سبعة (7) أيام من تاريخ الشراء.<br /><br />
                            - يرجى التواصل معنا عبر صفحة اتصل بنا أو عبر أرقامنا الهاتفية من أجل طلب الإسترجاع أو الإستبدال.<br /><br />
                            - يرجى تصوير المنتج وإرساله مع تحديد المدينة والعنوان ورقم الطلب ليتم إستبداله بمنتج اخر في حالة كان المنتج فاسدا أو به عيب معين، أو لا يستغل وفق المتفق عليه.<br /><br />
                            - يتم إسترجاع المبلغ للعميل كاملا في حالة كان المنتج الذي توصل به مختلف تماما مع وصف المنتج في صفحة المنتج بموقعنا.<br /><br />
                            - لسنا مسؤولين عن أي توقعات لإستعمال المنتجات من طرف العميل لم نذكرها بصفحة المنتج بموقعنا.<br /><br />
                            - تخصم 30 بالمائة أو قيمة لا تقل عن 300 دج إذا كان العميل لا يريد المنتج وليس به عيب ولا أي مشكل يذكر.<br /><br />
                        </p></li>
                    </ul>
                </p>
            </div>
        </>
    )
}
export default ReturnPolicy;