/*eslint-disable*/
import React from "react";
export default function Footer() {
  return (
    <div className=" z-[5]  mx-auto flex w-full max-w-screen-sm flex-col items-center justify-between px-[20px] pb-4 lglog:mb-6 lglog:max-w-[100%] lglog:flex-row xllog:mb-2 xllog:w-[1310px] xllog:pb-6">
      <p className="mb-6 ms-14 text-center text-sm text-gray-600 mdlog:text-base lglog:mb-0">
        جميع الحقوق محفوظة ©{1900 + new Date().getYear()} Ecom DZ.  {/*All Rights Reserved. */}
      </p>
      {/* <ul className="flex flex-wrap items-center smlog:flex-nowrap">
        <li className="mr-12">
          <a
            target="blank"
            href="mailto:hello@simmmple.com"
            className="text-sm text-gray-600 hover:text-gray-600 mdlog:text-base lglog:text-white lglog:hover:text-white"
          >
            Support
          </a>
        </li>
        <li className="mr-12">
          <a
            target="blank"
            href="https://simmmple.com/licenses"
            className="text-sm text-gray-600 hover:text-gray-600 mdlog:text-base lglog:text-white lglog:hover:text-white"
          >
            License
          </a>
        </li>
        <li className="mr-12">
          <a
            target="blank"
            href="https://simmmple.com/terms-of-service"
            className="text-sm text-gray-600 hover:text-gray-600 mdlog:text-base lglog:text-white lglog:hover:text-white"
          >
            Terms of Use
          </a>
        </li>
        <li>
          <a
            target="blank"
            href="https://blog.horizon-ui.com/"
            className="text-sm text-gray-600 hover:text-gray-600 mdlog:text-base lglog:text-white lglog:hover:text-white"
          >
            Blog
          </a>
        </li>
      </ul> */}
    </div>
  );
}
