import React from 'react';
import { useNavigate } from "react-router-dom";
import Notfound from './NotFound';

const ThankPage = () => {

    const navigate = useNavigate();
    const titla = localStorage.getItem('title');
    const price = parseInt(localStorage.getItem('price'));
    const ship_price = parseInt(localStorage.getItem('ship_price'));
    const qty = parseInt(localStorage.getItem('qty'));
    const prices = price * qty;
    function rederict(ver) {
        if (ver === 1) { localStorage.clear(); }
        navigate((ver === 0) ? '/not_found' : '/', { replace: true })
    }
    return (
        <>
            {!(titla == null) ?
                <div className="container text-center">
                    <div className="order-message  ">
                        <ul>
                            <li>
                                <div className="flex justify-center  mt-3 mb-3">
                                    {/* <img className="h-[100px] w-[120px]" src={logos} alt="Order success" /> */}
                                    <svg className='h-[100px] w-[120px]' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="1024px" height="768px" viewBox="0 0 1024 768" enableBackground="new 0 0 1024 768" xmlSpace="preserve">
                                        <g>
                                            <g>
                                                <path fill="#abdb6c" d="M715.517,393.308H597.982V309.85c0-38.81-11.517-67.05-34.393-83.773    c-35.97-26.347-84.878-11.517-86.929-10.728c-7.888,2.524-13.252,9.781-13.252,17.985v98.919c0,30.606-14.672,56.48-43.543,77.305    c-18.774,13.568-38.021,20.667-44.805,22.876c-5.048-7.73-13.726-12.779-23.665-12.779H249.795    c-15.619,0-28.24,12.779-28.24,28.24V724.93c0,15.619,12.779,28.24,28.24,28.24h101.601c6.942,0,13.252-2.524,18.301-6.626    C384.684,759.954,404.404,768,425.703,768h242.169c23.98,0,45.279-5.837,61.528-16.723c20.667-13.883,33.604-36.286,37.075-64.526    l31.869-202.097c0.473-2.998,0.631-5.837,0.631-8.677C797.239,430.541,759.691,393.308,715.517,393.308z M341.772,715.622H259.26    V457.676h82.511V715.622z M760.953,478.975l-31.869,202.412c0,0.158,0,0.473-0.158,0.631c-1.42,11.99-5.837,48.276-61.055,48.276    H425.703c-22.087,0-41.019-15.776-45.121-37.39c-0.158-0.947-0.473-1.893-0.789-2.84V470.455c0.947-0.158,2.051-0.316,2.998-0.631    c1.262-0.316,29.66-8.362,58.215-28.713c39.441-27.924,60.266-65.63,60.266-108.858v-83.458    c11.359-1.262,27.924-1.104,39.915,7.73c12.463,9.15,18.932,27.136,18.932,53.167v102.389c0,10.413,8.519,18.932,18.932,18.932    h136.467c23.823,0,44.647,21.298,45.594,46.541C761.111,477.712,761.111,478.186,760.953,478.975z" />
                                                <path fill="#989898" d="M529.354,160.289c10.413,0,18.932-8.519,18.932-18.932V18.932C548.286,8.519,539.767,0,529.354,0    c-10.412,0-18.932,8.519-18.932,18.932V141.2C510.422,151.77,518.942,160.289,529.354,160.289z" />
                                                <path fill="#989898" d="M642.314,293.916c0,10.413,8.519,18.932,18.932,18.932h122.268c10.412,0,18.932-8.519,18.932-18.932    s-8.519-18.932-18.932-18.932H661.246C650.833,274.984,642.314,283.504,642.314,293.916z" />
                                                <path fill="#989898" d="M275.037,312.848h122.268c10.413,0,18.932-8.519,18.932-18.932s-8.519-18.932-18.932-18.932H275.037    c-10.412,0-18.932,8.519-18.932,18.932S264.624,312.848,275.037,312.848z" />
                                                <path fill="#070707" d="M327.257,108.069c5.048,0,9.939-2.051,13.41-5.522s5.522-8.362,5.522-13.41    c0-5.048-2.051-9.939-5.522-13.41s-8.362-5.522-13.41-5.522c-5.048,0-9.939,2.051-13.41,5.522s-5.522,8.362-5.522,13.41    c0,5.048,2.051,9.939,5.522,13.41C317.318,106.176,322.209,108.069,327.257,108.069z" />
                                                <path fill="#070707" d="M370.485,151.297c4.891,0,9.624-1.893,13.41-5.522c7.415-7.415,7.415-19.405,0-26.82    c-7.415-7.415-19.405-7.415-26.82,0c-7.415,7.415-7.415,19.405,0,26.82C360.703,149.561,365.594,151.297,370.485,151.297z" />
                                                <path fill="#070707" d="M413.712,194.682c5.048,0,9.939-2.051,13.41-5.522s5.522-8.362,5.522-13.41    c0-5.048-2.051-9.939-5.522-13.41s-8.362-5.522-13.41-5.522c-5.048,0-9.939,2.051-13.41,5.522s-5.522,8.362-5.522,13.41    c0,5.048,2.051,9.939,5.522,13.41S408.664,194.682,413.712,194.682z" />
                                                <path fill="#070707" d="M718.514,116.904c5.049,0,9.939-2.051,13.41-5.522s5.522-8.362,5.522-13.41s-2.051-9.939-5.522-13.41    s-8.362-5.522-13.41-5.522c-5.048,0-9.939,2.051-13.41,5.522c-3.471,3.471-5.522,8.362-5.522,13.41s2.051,9.939,5.522,13.41    C708.575,115.011,713.466,116.904,718.514,116.904z" />
                                                <path fill="#070707" d="M675.287,160.289c4.891,0,9.624-1.893,13.41-5.522c7.415-7.415,7.415-19.405,0-26.82    s-19.405-7.415-26.82,0s-7.415,19.405,0,26.82C665.505,158.396,670.396,160.289,675.287,160.289z" />
                                                <path fill="#070707" d="M632.059,203.517c5.049,0,9.939-2.051,13.41-5.522c3.471-3.471,5.522-8.362,5.522-13.41    c0-5.048-2.051-9.939-5.522-13.41c-3.471-3.471-8.362-5.522-13.41-5.522c-5.048,0-9.939,2.051-13.41,5.522    c-3.471,3.471-5.522,8.362-5.522,13.41c0,5.048,2.051,9.939,5.522,13.41C622.12,201.466,627.011,203.517,632.059,203.517z" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </li>
                            <li className='mb-4'>
                                <div className="message-body font-bold text-lg">
                                    <div className="text-3xl text-green-500 mb-3">
                                        <span className="heading-primary">شكرا جزيلاً على ثقتكم</span>
                                    </div>
                                    <div className="actions">
                                        <span className="text-2xl px-2" >تم استلام طلبك ,سيتصل بك فريقنا لتأكيد الطلب.</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <ul className='border border-black mb-11 mx-3 lgg:mx-64 text-xl font-semibold rounded-sm'>
                                    <li className='border-b border-black bg-purple-700 py-2 font-bold'>


                                        <span>
                                            <i className='bx bx-cart bx-sm' />
                                        </span>
                                        <span>
                                            ملخص الطلبية
                                        </span>
                                    </li>
                                    <li className='text-2xl border-b border-black py-2 flex justify-center' >
                                        <span className='text-white bg-green-500 text-sm me-3 mb-6 mt-3 p-1 ms-1'>
                                            X{qty}
                                        </span>
                                        <span className='mt-3 xsm:line-clamp-1 xsm:me-6'>
                                            {titla}
                                        </span>

                                    </li>
                                    <li className='border-b border-black flex justify-between px-6 py-2'>
                                        <span>
                                            سعر
                                        </span>
                                        <span>
                                            <span className='pe-2'>  {prices} </span>
                                            <span>دج</span>
                                        </span>

                                    </li>
                                    <li className='border-b border-black flex justify-between px-6 py-2'>

                                        <span>
                                            سعر  التوصيل
                                        </span>
                                        <span>
                                            <span className='pe-4'>{ship_price}</span>
                                            <span>دج</span>

                                        </span>
                                    </li>
                                    <li className='border-b flex justify-between bg-gray-300  px-6 py-4'>
                                        <span>
                                            المجموع
                                        </span>
                                        <span className='font-bold'>
                                            <span className='pe-3'>{prices + ship_price}</span>
                                            <span>دج</span>
                                        </span>

                                    </li>

                                </ul>
                            </li>
                            <li>
                                <span className="gohome  cursor-pointer p-2 bg-green-600 text-white font-bold text-xl  rounded-lg w-auto  active:bg-green-800 hover:cursor-pointer hover:bg-green-800 hover:text-yellow-500" onClick={() => rederict(1)}>العودة الى الصفحة الرئيسية</span>
                                <br />
                                <br />
                                <br />
                            </li>
                        </ul>


                    </div>
                </div>
                : <Notfound />
            }
        </>
    );
};
export default ThankPage;