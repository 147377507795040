import React from "react";

// Admin Imports
// import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import DataTables from "views/admin/tables";
// import RTLDefault from "views/rtl/default";

// Auth Imports
// import SignIn from "views/auth/SignIn";
import Addproduct from "../Add_product";
import Orders from "../Orders"
import Products_list from "../Products_list"
import SignIn from "./auth/SignIn"
// Icon Imports
import {
  // MdHome,
  // MdOutlineShoppingCart,
  // MdBarChart,
  // MdPerson,
  MdLock,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { TfiPackage } from "react-icons/tfi";
import { FcMoneyTransfer } from "react-icons/fc";

const routes = [
  {
    name: "المنتجات",
    layout: "/admin",
    path: "product",
    icon: <TfiPackage className="h-6 w-6" />,
    active_icon: <TfiPackage className="h-8 w-8" />,
    // eslint-disable-next-line 
    component: <Products_list />,
  },
  {
    name: "عرض الطلبات",
    layout: "/admin",
    path: "order",
    icon: <FcMoneyTransfer className="h-6 w-6 " />,
    active_icon: <FcMoneyTransfer className="h-8 w-8" />,
    component: <Orders />,
    secondary: true,
  },
  {
    name: "اضافة منتج",
    layout: "/admin",
    icon: <MdProductionQuantityLimits className="h-6 w-6" />,
    active_icon: <MdProductionQuantityLimits className="h-8 w-8" />,
    path: "add_new/*",
    component: <Addproduct />,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Orders />,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <Orders />,
  // },
];
export default routes;
