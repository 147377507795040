import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
    document.title = ('Page not found');
    return (
        <>
            <div className="container text-center w-full    px-3 mx-1 mds:mx-8">
                <div className="content">
                    <div className="svg-holder flex justify-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="923.937" height="453.008" viewBox="0 0 923.937 453.008">
                            <defs>
                                <clipPath id="clip-path">
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="16.155" cy="16.155" r="16.155" transform="translate(333.051 89.317)" fill="#ef9e7b" />
                                </clipPath>
                                <clipPath id="clip-path-2">
                                    <path id="Path_24" data-name="Path 24" d="M341.048,193.448c-16.575.419-35.468-11.793-50.939-26.552-4.575-4.365-2.057-6.641,2.308-11.216A11.448,11.448,0,0,1,308.6,155.3c11.936,11.386,23.718,16.438,35.03,15.018,21.029-2.641,35.918-26.083,36.067-26.32a11.45,11.45,0,0,1,19.47,12.054c-.828,1.34-20.614,32.858-52.518,36.966A54.671,54.671,0,0,1,341.048,193.448Z" fill="#ef9e7b" />
                                </clipPath>
                            </defs>
                            <g id="Group_140" data-name="Group 140" transform="translate(-522 -303.725)">
                                <g id="Group_123" data-name="Group 123" transform="translate(438.956 283.84)" opacity="0.091">
                                    <path id="Path_1" data-name="Path 1" d="M219.462,393H75.744L68.8,329.608l131.56-181.494H303.7V323.529h36.037V393H303.7v52.971H219.462Zm0-69.473V234.086L155.2,323.529Z" transform="translate(14.247 26.919)" fill="#5c3a71" />
                                    <path id="Path_2" data-name="Path 2" d="M644.815,393H501.1l-6.949-63.394L625.711,148.114H729.05V323.529h36.037V393H729.05v52.971H644.815Zm0-69.473V234.086l-64.259,89.443Z" transform="translate(241.894 26.919)" fill="#5c3a71" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="158.392" cy="158.392" r="158.392" transform="translate(321.044 243.884) rotate(-45)" fill="#5c3a71" />
                                </g>
                                <g id="OBJECTS" transform="translate(654 317)">
                                    <g id="Group_4" data-name="Group 4">
                                        <g id="Group_3" data-name="Group 3">
                                            <path id="Path_4" data-name="Path 4" d="M475.576,127.081a8.069,8.069,0,1,1-7.66-8.457A8.069,8.069,0,0,1,475.576,127.081Z" fill="none" stroke="#f2b77c" strokeMiterlimit="10" strokeWidth="1.174" />
                                        </g>
                                    </g>
                                    <g id="Group_32" data-name="Group 32">
                                        <g id="Group_31" data-name="Group 31">
                                            <g id="Group_25" data-name="Group 25">
                                                <g id="Group_9" data-name="Group 9">
                                                    <path id="Path_5" data-name="Path 5" d="M347.715,115.583c.321,1.059,15.473,21.641,15.161,21.108L373.584,124.1l-17.909-13.893Z" fill="#ef9e7b" />
                                                    <path id="Path_6" data-name="Path 6" d="M358.285,109.43l-2.078,18.23-8.9-12.531Z" fill="#d67f61" />
                                                    <g id="Group_6" data-name="Group 6">
                                                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="16.155" cy="16.155" r="16.155" transform="translate(333.051 89.317)" fill="#ef9e7b" />
                                                        <g id="Group_5" data-name="Group 5" clipPath="url(#clip-path)">
                                                            <path id="Path_7" data-name="Path 7" d="M334.858,95.062s2.183,7.479,15.23,11.085l2.944,1.416s6.651,11.728,18,6.918c0,0,4.1-14-12.712-24.234C346.1,82.805,334.858,95.062,334.858,95.062Z" fill="#060e38" />
                                                        </g>
                                                    </g>
                                                    <path id="Path_8" data-name="Path 8" d="M334.538,112.318l-.622,3.882a.662.662,0,0,0,.807.75l2.624-.624Z" fill="#ef9e7b" />
                                                    <g id="Group_7" data-name="Group 7">
                                                        <path id="Path_9" data-name="Path 9" d="M358.873,105.458a5.155,5.155,0,1,1-4.921-5.379A5.155,5.155,0,0,1,358.873,105.458Z" fill="#ef9e7b" />
                                                        <path id="Path_10" data-name="Path 10" d="M351.714,107a3.836,3.836,0,0,1,3.212-3.956" fill="none" stroke="#060e38" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.839" />
                                                    </g>
                                                    <path id="Path_11" data-name="Path 11" d="M379.3,89.272a11.088,11.088,0,1,1-11.329-10.84A11.087,11.087,0,0,1,379.3,89.272Z" fill="#060e38" />
                                                    <g id="Group_8" data-name="Group 8">
                                                        <path id="Path_12" data-name="Path 12" d="M337.431,108.932a.954.954,0,1,1-1.218-.581A.955.955,0,0,1,337.431,108.932Z" fill="#060e38" />
                                                        <path id="Path_13" data-name="Path 13" d="M334.507,105.8a3.235,3.235,0,0,1,3.415-.336" fill="none" stroke="#060e38" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.839" />
                                                        <path id="Path_14" data-name="Path 14" d="M339.995,117.193s0-1.224,1.661-1.361" fill="none" stroke="#060e38" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.839" />
                                                    </g>
                                                </g>
                                                <g id="Group_17" data-name="Group 17">
                                                    <g id="Group_12" data-name="Group 12">
                                                        <g id="Group_11" data-name="Group 11">
                                                            <path id="Path_15" data-name="Path 15" d="M439.43,372.775l13.1,9.317,7.806-14.046-13.571-9.719Z" fill="#ef9e7b" />
                                                            <g id="Group_10" data-name="Group 10">
                                                                <path id="Path_16" data-name="Path 16" d="M448.376,383.306l12.413-17.448,18.517,12.62L455.7,411.664a1.858,1.858,0,0,1-3.363-1.263l2.919-22.038Z" fill="#060e38" />
                                                                <line id="Line_1" data-name="Line 1" y1="33.654" x2="23.941" transform="translate(452.144 376.187)" fill="none" stroke="#ffdabc" strokeMiterlimit="10" strokeWidth="1.133" />
                                                                <line id="Line_2" data-name="Line 2" y1="1.159" x2="3.325" transform="translate(453.485 397.319)" fill="none" stroke="#ffdabc" strokeMiterlimit="10" strokeWidth="1.133" />
                                                                <line id="Line_3" data-name="Line 3" y1="1.159" x2="3.325" transform="translate(454.053 393.006)" fill="none" stroke="#ffdabc" strokeMiterlimit="10" strokeWidth="1.133" />
                                                            </g>
                                                        </g>
                                                        <path id="Path_17" data-name="Path 17" d="M398.757,209.156l-34.395,5.963S334.386,307.811,436.293,379.9c0,0,11.059-8.358,20.017-24.6,0,0-78.072-46.968-57.553-146.145" fill="#012442" />
                                                    </g>
                                                    <g id="Group_16" data-name="Group 16">
                                                        <g id="Group_14" data-name="Group 14">
                                                            <path id="Path_18" data-name="Path 18" d="M320.761,394.621V410.7H337.3L337.354,394Z" fill="#ef9e7b" />
                                                            <g id="Group_13" data-name="Group 13">
                                                                <path id="Path_19" data-name="Path 19" d="M317.745,409.2h21.414l.45,22.4H298.882a1.858,1.858,0,0,1-.92-3.472l19.649-10.4Z" fill="#060e38" />
                                                                <line id="Line_4" data-name="Line 4" x2="41.301" transform="translate(298.308 427.656)" fill="none" stroke="#ffdabc" strokeMiterlimit="10" strokeWidth="1.133" />
                                                                <line id="Line_5" data-name="Line 5" x2="2.872" y2="2.038" transform="translate(308.344 422.162)" fill="none" stroke="#ffdabc" strokeMiterlimit="10" strokeWidth="1.133" />
                                                                <line id="Line_6" data-name="Line 6" x2="2.872" y2="2.038" transform="translate(312.189 420.124)" fill="none" stroke="#ffdabc" strokeMiterlimit="10" strokeWidth="1.133" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_15" data-name="Group 15">
                                                            <path id="Path_20" data-name="Path 20" d="M362.7,187.109l1.423,7.1s-48.076,69.064-50.51,204.658c0,0,16.745,3.69,31.155,0,0,0-1.917-114.528,45.788-166.791,0,0,22.385-18.721,9.63-49.156Z" fill="#005482" />
                                                            <path id="Path_21" data-name="Path 21" d="M313.864,389.154a141.576,141.576,0,0,0,31,0" fill="none" stroke="#060e38" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.839" />
                                                            <line id="Line_7" data-name="Line 7" y1="3.445" x2="38.166" transform="translate(364.459 190.712)" fill="none" stroke="#060e38" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.839" />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Group_24" data-name="Group 24">
                                                    <g id="Group_21" data-name="Group 21">
                                                        <g id="Group_19" data-name="Group 19">
                                                            <path id="Path_22" data-name="Path 22" d="M341.048,193.448c-16.575.419-35.468-11.793-50.939-26.552-4.575-4.365-2.057-6.641,2.308-11.216A11.448,11.448,0,0,1,308.6,155.3c11.936,11.386,23.718,16.438,35.03,15.018,21.029-2.641,35.918-26.083,36.067-26.32a11.45,11.45,0,0,1,19.47,12.054c-.828,1.34-20.614,32.858-52.518,36.966A54.671,54.671,0,0,1,341.048,193.448Z" fill="#ef9e7b" />
                                                            <g id="Group_18" data-name="Group 18" clipPath="url(#clip-path-2)">
                                                                <path id="Path_23" data-name="Path 23" d="M358.416,160.262l-.619,37.372,28.406-9.525-16.838-33.361Z" fill="#d67f61" />
                                                            </g>
                                                        </g>
                                                        <path id="Path_25" data-name="Path 25" d="M309,159.673l-.437-3.66,1.6.732-5.036-10.093a5.642,5.642,0,0,0-1.751-2.132c-1.751-1.236-3.156-.7-4.409-.829-2.1-.225-1.449.4-1.481,2.514h0c-2.2.035-6.722,1.018-9.212-8.673a3.949,3.949,0,0,0-4.757-2.826c-3.141.784,2.3-.665,2.152.753-.006.058,0,.119,0,.178a3.119,3.119,0,0,0-5.232,1.947,3.059,3.059,0,0,0-.01.507,3.109,3.109,0,0,0-3.82,3.76c.032.131.071.251.1.381-.055.01-.111.01-.166.024-1.307.325-1.372.913-1.508,2.179a3.142,3.142,0,0,0,.076,1.087,23.618,23.618,0,0,0,2.386,5.9h-.006l.05.094a22.523,22.523,0,0,0,1.57,2.658c3.167,5.054,11.119,14.949,20.162,17.182C311.3,174.331,309,159.673,309,159.673Z" fill="#ef9e7b" />
                                                        <g id="Group_20" data-name="Group 20">
                                                            <path id="Path_26" data-name="Path 26" d="M270.177,130.247l.06,1.807a2.527,2.527,0,0,0,.582,1.547l22.615,27.138a4.5,4.5,0,0,0,2.876,1.743l9.922-.251a3.491,3.491,0,0,0,2.768-2.39h0l-29.777-28.618Z" fill="#03081d" />
                                                            <path id="Path_27" data-name="Path 27" d="M270.429,130.935l24.722,29.013a2.6,2.6,0,0,0,2.008.912l10.789-.126a.982.982,0,0,0,.733-1.621l-24.673-28.7a3.478,3.478,0,0,0-2.68-1.211l-10.164.119A.981.981,0,0,0,270.429,130.935Z" fill="#060e38" />
                                                            <path id="Path_28" data-name="Path 28" d="M274.8,131.928l22.412,26.3a1.848,1.848,0,0,0,1.429.649l6.141-.072a.871.871,0,0,0,.65-1.439l-21.911-25.491a3.09,3.09,0,0,0-2.38-1.076l-5.86.068A.642.642,0,0,0,274.8,131.928Z" fill="#f7f4ed" />
                                                        </g>
                                                        <path id="Path_29" data-name="Path 29" d="M301.313,143.722l-5.456-.835a3,3,0,0,0-3.246,4.079h0a3.007,3.007,0,0,0,1.471,1.589l9.812,4.8-1.447-6.323Z" fill="#ef9e7b" />
                                                    </g>
                                                    <g id="Group_22" data-name="Group 22">
                                                        <path id="Path_30" data-name="Path 30" d="M358.859,137.606s-3.647-4.007,2.989-11.361c7.662-8.493,11.9-5.786,15.505-4.139Z" fill="#d80650" />
                                                        <path id="Path_31" data-name="Path 31" d="M400.181,182.917s-10.274-14.648-8.939-26.056,4.089-31.364-11.7-36.473a20.315,20.315,0,0,0-13.053,6.037c-5.23,4.534-27.809,19.517-16.267,30.693,1.105,1.312,6.852,3.133,7.687,4.137a147.848,147.848,0,0,0,4.786,25.854Z" fill="#f03c3a" />
                                                        <path id="Path_32" data-name="Path 32" d="M382.2,155.192l9.269,28.7,13.877-1.533-26.463-41.676Z" fill="#d9004b" />
                                                    </g>
                                                    <g id="Group_23" data-name="Group 23">
                                                        <path id="Path_33" data-name="Path 33" d="M414.26,236.565a12.088,12.088,0,0,0,.284,1.456L408.807,248.5a3.66,3.66,0,0,0,5.947,4.187l1.17-1.32c1.735,1.445,3.834,4.936-.873,14.278a3.186,3.186,0,0,0,5.024,3.757c.044-.041.08-.091.121-.135a3.182,3.182,0,0,0,5.341,1.976,3.025,3.025,0,0,0,.345-.386,3.151,3.151,0,0,0,1.179,1.047,3.179,3.179,0,0,0,4.277-1.412c.062-.123.112-.241.173-.364.05.029.093.066.145.093a3.182,3.182,0,0,0,4.277-1.412,35.986,35.986,0,0,0,2.8-7.279l.005,0,.023-.106a22.8,22.8,0,0,0,.555-3.1c.745-4.9,1.649-14.479-1.016-22.05a12.092,12.092,0,0,0-.026-2.74c-5.356-42.422-23.687-74.659-36.662-92.851L382.2,155.192C393.436,171.069,409.577,199.481,414.26,236.565Z" fill="#ef9e7b" />
                                                        <path id="Path_34" data-name="Path 34" d="M388.06,123.994a12.1,12.1,0,0,0-17.253,16.981A128.622,128.622,0,0,1,382.2,155.192l19.413-14.51A147.02,147.02,0,0,0,388.06,123.994Z" fill="#f03c3a" />
                                                        <line id="Line_8" data-name="Line 8" x1="11.395" y1="14.217" transform="translate(370.807 140.975)" fill="none" stroke="#060e38" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.839" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_30" data-name="Group 30">
                                                <g id="Group_26" data-name="Group 26" transform="translate(0 -6)">
                                                    <line id="Line_9" data-name="Line 9" x2="5.777" y2="2.455" transform="translate(227.334 82.775)" fill="none" stroke="#533b74" strokeMiterlimit="10" strokeWidth="1.133" />
                                                    <line id="Line_10" data-name="Line 10" y2="5.073" transform="translate(241.881 73.94)" fill="none" stroke="#533b74" strokeMiterlimit="10" strokeWidth="1.133" />
                                                    <line id="Line_11" data-name="Line 11" x2="5.35" y2="8.184" transform="translate(230.436 71.623)" fill="none" stroke="#533b74" strokeMiterlimit="10" strokeWidth="1.133" />
                                                </g>
                                                <g id="Group_29" data-name="Group 29">
                                                    <g id="Group_27" data-name="Group 27">
                                                        <rect id="Rectangle_3" data-name="Rectangle 3" width="64.728" height="30.087" rx="2.309" transform="translate(239 77)" fill="#533c74" />
                                                        <path id="Path_35" data-name="Path 35" d="M264.852,105.4l6.064,6.872,6.266-6.872Z" fill="#533b74" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </div>
                    <div className="info-holder  font-bold text-xs">
                        <p className="ntfound text-xl mdd:pt-6"><strong>Ouups !</strong> Page not found</p>
                        <br />

                        <p className="arbntfound text-sm mdd:text-lg ">تم نقل الصفحة التي تبحث عنها أو إزالتها أو إعادة تسميتها أو ربما لم تكن موجودة على الإطلاق.</p>
                        <br />
                        <br />
                        <Link to={'/'}>   <span className="gohome  p-2 bg-green-600 text-white font-bold text-sm mdd:text-lg rounded-sm   h-8 mdd:h-11 active:bg-green-800 hover:cursor-pointer hover:bg-green-800 hover:text-yellow-500">العودة الى الصفحة الرئيسية</span></Link>
                        <br />
                        <br />
                        <br />
                        <br />

                    </div>
                </div>
            </div>
        </>
    )
}


export default Notfound;
