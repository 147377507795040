import React from 'react';

const Aboutus = () => {
    document.title = ('عن المتجر');
    return (
        <>
            <div>
                <ul className='px-11'>
                    <li className='font-bold text-2xl mb-8 mt-3 text-center' >عن المتجر</li>

                    <li>
                        هذا المتجر بوابتك الجديدة للتسوق إلكترونيا بشكل سهل وبسيط.<br />

                        نوفر لك منتجات متععدة ذات جودة عالية لتختار منها الأفضل وبسعر تنافسي لن تجده في أي مكان أخر. التسوق معنا عملية ممتعة وأمنة. ونوفر لك كل ما تحتاجه من التسهيلات سواء في اختيار المنتج أو في عملية الدفع أو في عملية الشحن.

                        <br /><br />
                    </li>


                </ul>
            </div >
        </>
    )
}
export default Aboutus;