import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const Footer = () => {
    const [hidde, sethidde] = useState(false);
    const excludedRoutes = ['login', 'admin', 'thank_page', 'auth'];
    const location = useLocation();
    useEffect(() => {
        (!excludedRoutes.includes((window.location.pathname.split('/')[1]))) ? sethidde(true) : sethidde(false);
        // eslint-disable-next-line 
    }, [location.pathname]);

    return (
        <>
            {hidde &&
                <div className='bg-gray-100'>
                    <div className='flex justify-center py-8'>
                        <Link to="/">
                            <img src="https://cdn.youcan.shop/stores/f58557b5767b4b1a03bdbe3baa00f008/others/FwQFCsc8Rp56LiMNBKDntvNrCOhVPXaUb5yRkmUT.png" alt="" className='image h-16 w-24' />
                        </Link>
                    </div>
                    <div className='footer w-auto grid grid-cols-1 lgg:grid-cols-3 justify-between gap-1  mb-8 mt-3 content-center text-center '>

                        <section className='sec1 grid grid-cols-1 pb-6' >
                            <span className='font-bold text-lg underline underline-offset-8 mb-5' > عن المتجر</span>
                            <Link className=' hover:text-green-500 pb-3 ' to="/about-us"> <span> من نحن</span></Link>
                            <Link className=' hover:text-green-500 pb-3' to="/how-to-pay"> <span>طرق الدفع</span></Link>
                            <Link className=' hover:text-green-500 pb-3' to="/shipping-delivery"> <span>الشحن والتسليم</span></Link>
                        </section>
                        <section className='sec3 grid grid-cols-1 pb-6' >
                            <span className='font-bold text-lg underline underline-offset-8 mb-5'>الشروط والسياسات</span>
                            <Link className=' hover:text-green-500 pb-3' to="/terms_conditions"> <span>شروط الاستخدام</span></Link>
                            <Link className=' hover:text-green-500 pb-3' to="/confidentiality"> <span>سياسة الخصوصية</span></Link>
                            <Link className=' hover:text-green-500 pb-3' to="/return_policy"> <span>سياسة الاستبدال و الاسترجاع</span></Link>
                        </section>
                        <section className='sec2 grid grid-cols-1 pb-6' >
                            <span className='font-bold text-lg underline underline-offset-8 mb-5' >تواصل معنا</span>
                            <Link className=' hover:text-green-500 pb-3' to="/asked-quest"> <span> الأسئلة المتكررة</span></Link>
                            <Link className=' hover:text-green-500 pb-3' to="/contact-us"> <span>اتصل بنا</span></Link>
                            <Link className=' hover:text-green-500 pb-3  w-auto' to="tel:0672333762"> <span>هاتف</span></Link>
                        </section>




                    </div>
                    <div className='droit text-center text-xs h-8 p-1 bg-gray-300' > جميع الحقوق محفوظة © {new Date().getFullYear()}</div>
                    <div className='droit text-center text-xs h-8 pb-8 bg-gray-300' > تم برمجة المنصة من طرف: <span className='font-bold text-sm text-red-600'>
                        Boudjada Med Elamine</span></div>
                </div>
            }
        </>
    )

}
export default Footer;