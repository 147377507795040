
import { React, useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrash, faCopy, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
//import Helmet  from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const ProductsList = () => {
    const [datas, setdatas] = useState([]);
    // const token = localStorage.getItem('logintoken');
    const token = Cookies.get('token');
    const navigate = useNavigate();

    const currentdomain = window.location.origin.split(':3000')[0];
    const urla = currentdomain + '/bk/prod_list.php';
    const [refrach, setrefrach] = useState(0);
    const [ordrby, setordrby] = useState(false);



    const [selectedItems, setSelectedItems] = useState([]);
    const [deleteId, setdeleteId] = useState(0);
    const [multi, setmulti] = useState(0);

    let [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false)
    }
    function yesmodel(multi) {
        setIsOpen(false);
        (multi === 0) ? delete_post(deleteId) : delete_multi_post(selectedItems);

    }

    function openModal(prod_id) {
        setmulti(0);
        setdeleteId(prod_id);
        setIsOpen(true);
    }
    function openMultiModal() {
        setmulti(1);
        setIsOpen(true);
    }

    // for paginaionnnnn *********************************

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(50);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = datas.slice(indexOfFirstItem, indexOfLastItem);
    const [ispagination, setispagination] = useState(false);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const prod_id = 'a';
    //**************end pagination***********************/
    const showToastMessageSuccus = () => {
        toast.success("! تمت العملية بنجاح ", {
        });
    };
    const showToastMessageError = () => {
        toast.error("!حدث خطأ فشل العملية", {
        });
    };

    function calculateTimeDifference(data) {
        if (data == null) return 'Loading...';
        const databaseDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - databaseDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(currentDate.getMonth() - databaseDate.getMonth() + (12 * (currentDate.getFullYear() - databaseDate.getFullYear())));
        const years = Math.floor(currentDate.getFullYear() - databaseDate.getFullYear());

        if (years > 0) {
            return `منذ ${years} ${years === 1 ? 'سنة' : 'سنوات'}`;
        } else if (months > 0) {
            return `منذ ${months} ${months === 1 ? 'شهر' : 'أشهر'}`;
        } else if (weeks > 0) {
            return `منذ ${weeks} ${weeks === 1 ? 'اسبوع' : 'أسابيع'}`;
        }
        if (days > 0) {
            return `منذ ${days}  ${days === 1 ? 'يوم' : 'أيام'} `;
        } else if (hours > 0) {
            return `منذ ${hours}  ${hours === 1 ? 'ساعة' : 'ساعات'}`;
        } else if (minutes > 0) {
            return `منذ ${minutes} ${minutes === 1 ? 'دقيقة' : 'دقائق'} `;
        } else {
            return 'منذ قليل';
        }
    };
    function sortByHidden() {
        //  const sortedData = [...datas].sort((a, b) => new Date(b.colum) - new Date(a.colum));
        const sortedData = ordrby ? [...datas].sort((a, b) => (parseInt(b.hidden)) - (parseInt(a.hidden))) : [...datas].sort((a, b) => (parseInt(a.hidden)) - (parseInt(b.hidden)));
        setordrby(!ordrby);
        setdatas(sortedData);
    };
    function sortByDate() {
        // const sortedData = ordrby ? [...datas].sort((a, b) => (new Date(b.date)) - new Date(a.date)) : [...datas].sort((a, b) => (new Date(a.date)) - new Date(b.date));
        // setordrby(!ordrby);
        // setdatas(sortedData);
        setdatas(prevItems => [...prevItems].reverse());
    };

    function sortByPrice() {
        const sortedData = ordrby ? [...datas].sort((a, b) => (parseInt(b.price)) - (parseInt(a.price))) : [...datas].sort((a, b) => (parseInt(a.price)) - (parseInt(b.price)));
        setordrby(!ordrby);
        setdatas(sortedData);
    };

    function sortByNbrOrder() {
        const sortedData = ordrby ? [...datas].sort((a, b) => (parseInt(b.num_orders)) - (parseInt(a.num_orders))) : [...datas].sort((a, b) => (parseInt(a.num_orders)) - (parseInt(b.num_orders)));
        setordrby(!ordrby);
        setdatas(sortedData);
    };
    function sortByViews() {
        //  const sortedData = [...datas].sort((a, b) => new Date(b.colum) - new Date(a.colum));
        const sortedData = ordrby ? [...datas].sort((a, b) => (parseInt(b.views_nbr)) - (parseInt(a.views_nbr))) : [...datas].sort((a, b) => (parseInt(a.views_nbr)) - (parseInt(b.views_nbr)));
        setordrby(!ordrby);
        setdatas(sortedData);
    };

    function checkboxHandler(e) {
        let isSelected = e.target.checked;
        let value = e.target.value;

        if (isSelected) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems((prevData) => {
                return prevData.filter((id) => {
                    return id !== value;
                })
            })
        }
    }


    function checkAllHandler() {


        if (currentItems.length === selectedItems.length) {
            setSelectedItems([]);
        } else {
            const postIds = currentItems.map((item) => {
                return item.title_id;
            })

            setSelectedItems(postIds);
        }
    }
    const handleChangePagination = event => {
        setCurrentPage(1);
        Cookies.set("pagination_products", event.target.value)
        setitemsPerPage((event.target.value));
    };


    useEffect(

        function () {
            async function fetchData() {

                try {
                    const response = await fetch(urla, {
                        method: 'POST',
                        headers: {
                            // 'Content-Type': 'text/html',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ prod_id }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');

                    }

                    console.log('success');
                    const data = await response.json();
                    if (data.success === 1) {
                        setdatas(data.datas.products)
                    }
                    else if (data.success === 2) {
                        if (Cookies.get('token')) {
                            Cookies.remove('token');
                        }
                        navigate('/auth/', { replace: true });
                    }

                    else {
                        alert(data.datas);
                    }

                } catch (error) {
                    console.error('Error:', error);

                }

            }
            fetchData();
            document.title = ('المنتجات');
            // eslint-disable-next-line
        }, [refrach, urla]
    );
    useEffect(

        function () {
            if (Cookies.get('pagination_products')) { setitemsPerPage(Cookies.get('pagination_products')) }
            else { setitemsPerPage(50) }
            ((Math.ceil(datas.length / itemsPerPage)) > 1) ? setispagination(true) : setispagination(false);

        }, [itemsPerPage, datas]
    );

    useEffect(
        //uncheck box when switch page
        function () {
            setSelectedItems([]);
            console.log(currentPage);
        }, [currentPage]
    );
    async function hided_post(prod_id, hide_state) {

        try {
            const response = await fetch(`${currentdomain}/bk/hide.php`, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'text/html',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ prod_id, hide_state }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');

            }

            console.log('success hidde');
            setrefrach(refrach + 1);
        } catch (error) {
            console.error('Error:', error);


        }

    }


    async function clone_post(prod_id) {

        try {
            const response = await fetch(`${currentdomain}/bk/clone.php`, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'text/html',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ prod_id }),
            });

            if (!response.ok) {
                showToastMessageError();
                throw new Error('Network response was not ok');

            }

            const data = await response.json();
            ((parseInt(data.success)) === 1) ? showToastMessageSuccus() : showToastMessageError();
            setrefrach(refrach + 1);
        } catch (error) {
            console.error('Error:', error);
            showToastMessageError();
        }

    }

    async function delete_post(prod_id) {

        try {
            const response = await fetch(`${currentdomain}/bk/delete.php`, {
                //  const response = await fetch('https://webhook.site/a964d2d2-f874-4f74-ba43-ba063962dbd7', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ prod_id: [prod_id] }),
            });

            if (!response.ok) {
                alert('9999999')
                showToastMessageError();
                throw new Error('Network response was not ok');

            }

            const data = await response.json();
            ((parseInt(data.success)) === 1) ? showToastMessageSuccus() : showToastMessageError();
            setrefrach(refrach + 1);
        } catch (error) {
            showToastMessageError();
            console.error('Error:', error);
        }

    }


    async function delete_multi_post(ids) {

        try {
            const response = await fetch(`${currentdomain}/bk/delete.php`, {
                //  const response = await fetch('https://webhook.site/a964d2d2-f874-4f74-ba43-ba063962dbd7', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ ids }),
            });

            if (!response.ok) {
                showToastMessageError();
                throw new Error('Network response was not ok');

            }

            const data = await response.json();
            setSelectedItems([]);
            ((parseInt(data.success)) === 1) ? showToastMessageSuccus() : showToastMessageError();
            setrefrach(refrach + 1);
        } catch (error) {
            showToastMessageError();
            console.error('Error:', error);
        }

    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="viewport" content={'width=1024'} />
                </Helmet>
            </HelmetProvider>

            <div className="contanet mb-11 mt-11 w-full px-3 ">

                <table className="flex justify-center text-sm">
                    <tr>
                        <td>


                            <Link to={{ pathname: '/admin/add_new/' }} >
                                <div className='orderbt flex justify-center m-4 h-8 w-24  font-bold  rounded-sm text-white bg-green-600    active:bg-yellow-400 cursor-pointer'>
                                    <button>جديد</button>
                                </div>
                            </Link>

                        </td>
                        <td>
                            <select name="state" onChange={handleChangePagination} className="trie my-3 h-8  w-28 text-center   border-solid border-2  border-gray-200  focus:outline-none font-semibold  rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-white" required >
                                <option value="5">5</option>
                                <option value="10" >10</option>
                                <option value="20">20</option>
                                <option value="50" selected>{itemsPerPage}</option>
                                <option value="100">100</option>
                            </select>
                        </td>
                        <td>
                            <div className={`orderbt flex justify-center m-4 h-8 w-24  font-bold  rounded-sm  ${(selectedItems.length === 0) ? 'text-black bg-gray-400  dark:bg-gray-700 dark:text-white opacity-25 dark:opacity-45 cursor-not-allowed' : 'text-white bg-green-600    active:bg-yellow-400 cursor-pointer'}`} ><button disabled={(selectedItems.length === 0)} onClick={() => openMultiModal()}>حذف {'(' + selectedItems.length + ')'} </button></div>

                        </td>




                    </tr>
                </table>

                <div className="bb border border-gray-300  ">

                    <ul className="bg-gray-200 dark:bg-gray-800 dark:text-white text-center  h-11 grid grid-cols-8 pt-2 text-lg font-bold">
                        <li>
                            <div className="flex items-center mt-1 ms-2">
                                <input id="default-checkbox" type="checkbox" checked={currentItems.length === selectedItems.length} onClick={() => checkAllHandler()} onChange={e => { }} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500  focus:ring-2 " />
                            </div>
                        </li>
                        <li>
                            <div className="cursor-pointer ">
                                الاسم
                            </div>

                        </li>
                        <li>
                            <div className="cursor-pointer" onClick={() => sortByPrice()}>
                                السعر
                            </div>

                        </li>
                        <li>
                            <div className="cursor-pointer" onClick={() => sortByNbrOrder()}>
                                الطلبيات
                            </div>

                        </li>
                        <li>
                            <div className="cursor-pointer" onClick={() => sortByHidden()}>
                                النشر
                            </div>

                        </li>
                        <li>
                            <div className="cursor-pointer" onClick={() => sortByViews()} >
                                المشاهدات
                            </div>

                        </li>
                        <li>
                            <div className="cursor-pointer" onClick={() => sortByDate()} >
                                تاريخ الانشاء
                            </div>

                        </li>
                        <li>
                            <div className="cursor-pointer">
                                الاجراءات
                            </div>

                        </li>

                    </ul>
                </div>
                {/* {Array.from({ length: 5 }, (_, index) => ( */}

                {/* {datas.map((item, index) => ( */}
                {/* //       {[...datas].reverse().map((item, index) => ( */}
                {currentItems.reverse().map((item, index) => (

                    < div key={index} className="bb border-b border-s border-e border-gray-300  " >
                        <ul className={` ${(index % 2 === 0) ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-700'}  dark:text-white hover:bg-green-100 hover:dark:bg-green-900 h-16 grid grid-cols-8 pt-3 text-start`}>
                            <li>
                                <div className="flex items-start text-center ms-2">
                                    <input id="default-checkbox" type="checkbox" checked={selectedItems.includes(item.title_id)} value={item.title_id} onChange={(e) => checkboxHandler(e)} className="w-4 h-4 me-3 cursor-pointer text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500  focus:ring-2 " />
                                    <Link key={index + 10} to={'/product/' + (item.title_id)} target="_blank">
                                        <img className="h-14 w-14 pb-2" src={item.img_default} alt="" />
                                    </Link>

                                </div>

                            </li>
                            <li>
                                <Link key={item.product_id + 10} to={'/product/' + (item.title_id)} >
                                    <div className="line-clamp-2 w-32 text-center font-semibold">
                                        {item.product_name}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <div className="items-center text-center mt-2 font-semibold text-sm">
                                    {item.price}
                                </div>

                            </li>
                            <li>
                                <div className="items-center text-center mt-2 font-semibold text-sm">
                                    {item.num_orders}
                                </div>

                            </li>
                            <li>
                                <div className={` items-center text-center w-11 p-1 mt-2 ms-12  ${item.hidden === "1" ? 'text-red-500 bg-red-200' : 'text-green-500 bg-green-200'} text-sm  font-semibold`}>
                                    {item.hidden === "1" ? 'مخفي' : 'مرئي '}
                                </div>

                            </li>
                            <li>
                                <div className=" items-center text-center font-semibold text-sm mt-3">
                                    {item.views_nbr}
                                </div>

                            </li>
                            <li>
                                <div className="line-clamp-2 items-center text-center font-semibold text-sm mt-3">
                                    {calculateTimeDifference(item.date)}
                                </div>

                            </li>
                            <li>
                                <div className="cursor-pointer grid grid-cols-4   items-center text-center ">
                                    <div className='icon rounded-full  bg-gray-200 dark:bg-gray-800  cursor-pointer mx-2 my-2' onClick={() => hided_post(parseInt(item.product_id), (item.hidden === "1") ? 0 : 1)} >

                                        {item.hidden === "1" ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </div>
                                    <Link to={{ pathname: '/admin/add_new/' + item.title_id, state: datas.find((row) => row.id === item.product_id) }} >
                                        <div className="icon rounded-full bg-gray-200 dark:bg-gray-800  cursor-pointer my-2 mx-3" >
                                            <FontAwesomeIcon icon={faPen} />
                                        </div>
                                    </Link>
                                    <div className="icon rounded-full bg-gray-200 dark:bg-gray-800  cursor-pointer mx-2 my-2" onClick={() => openModal(item.title_id)/*setitemsPerPage(2)*/}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    <div className="icon rounded-full bg-gray-200 dark:bg-gray-800  cursor-pointer mx-2 my-2" onClick={() => clone_post(parseInt(item.product_id))/*hided_post(parseInt(item.product_id), 0)*/}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </div>


                                </div>

                            </li>

                        </ul>
                    </div>
                ))}

                <div className="flex justify-center" >
                    {ispagination &&
                        <nav aria-label="Page navigation example" className="text-center mt-8 ms-11">
                            <ul className="flex items-center -space-x-px h-8 text-sm ">
                                <li className={`${(currentPage > 1) ? 'cursor-pointer' : 'invisible'}`} onClick={() => ((currentPage > 1) && paginate(currentPage - 1))}>
                                    <div className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 ">
                                        <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                                        </svg>
                                    </div>
                                </li>




                                {Array.from({ length: Math.ceil(datas.length / itemsPerPage) }).map((_, index) => (

                                    <li key={index + 1} onClick={() => (paginate(index + 1))}>
                                        <button className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${(currentPage === (index + 1)) ? 'bg-green-400 text-white hover:bg-green-400 hover:text-white' : 'bg-white text-gray-700'}`}> {index + 1}</button>
                                    </li>

                                ))}


                                <li className={`${(currentPage < Math.ceil(datas.length / itemsPerPage)) ? 'cursor-pointer' : 'invisible'}`} onClick={() => ((currentPage < Math.ceil(datas.length / itemsPerPage)) && paginate(currentPage + 1))}>
                                    <div className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700">

                                        <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    }
                </div>

                <ToastContainer position="bottom-right" />


                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black/25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-normal leading-6 text-gray-900 text-center"
                                        >

                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-lg font-bold text-black text-center">
                                                هل تود حذف المنتجات?
                                            </p>
                                        </div>

                                        <div className="mt-4 flex justify-between">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-green-300 px-11 py-2 ms-11 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                onClick={() => yesmodel(multi)}
                                            >
                                                نعم
                                            </button>
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-red-300 px-11 py-2  me-11 text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                                onClick={closeModal}
                                            >
                                                لا
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition >
            </div >
        </>
    )
}
export default ProductsList;
