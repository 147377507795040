import Checkbox from "./components/checkbox";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [expired, setexpired] = useState(1);
  const currentdomain = window.location.origin.split(':3000')[0];
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [islogin, setislogin] = useState(Cookies.get('token'));
  // eslint-disable-next-line
  const [isdark, setisdark] = useState(Cookies.get('dark'));


  useEffect(() => {
    document.title = 'تسجيل الدخول';
    if (islogin) {
      navigate('/admin', { replace: true });
    }
    if (isdark === undefined) { document.body.classList.remove("dark") }
    (isdark === 'true') ? (document.body.classList.add("dark")) : (document.body.classList.remove("dark"))

  }, [islogin, navigate, isdark]);

  const handleCheckboxChange = (checkedd) => {
    if (checkedd) { setexpired(180); }

  };

  async function handleSubmit() {
    // event.preventDefault();
    try {
      if ((email === '') || (password === '')) {
        alert('Input Empty');
      }

      else {

        const response = await axios.post(currentdomain + '/bk/login.php', { email, password });
        if (response.data.success === 1) {
          Cookies.set('token', response.data.datas.token, { expires: expired }); // Expires in 1 day
          Cookies.set('session_name', response.data.datas.name, { expires: expired }); // Expires in 1 day

          navigate('/admin', { replace: true });
        } else {
          alert('Login failed!');
        }
      }


    } catch (error) {
      console.error('There was an error logging in!', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  document.documentElement.dir = "rtl";
  return (
    <>
      <div>
        {
          (!islogin) &&
          <div className="mt-6 mb-16  flex h-full w-full items-center justify-center px-2 mdlog:mx-0 mdlog:px-0 lglog:mb-10 lglog:items-center lglog:justify-start">
            {/* Sign in section */}
            <div className="mt-[10vh] w-full max-w-full flex-col items-center mdlog:pl-4 lglog:pl-0 xllog:max-w-[420px]">
              <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                تسجيل الدخول
              </h4>
              <p className="mb-4 ml-1 text-base text-gray-600">
                {/* Enter your email and password to sign in! */}
                يرجا ادخل معلومات الدخول
              </p>

              <div className="mb-6 flex items-center  gap-0">
                <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                {/* <p className="text-base text-gray-600 dark:text-white"> or </p> */}
                <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
              </div>
              {/* Email */}

              <div className="mb-3">
                <label
                  htmlFor={55}
                  className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"
                >
                  {/* Email */}
                  ايمايل
                </label>
                <input
                  id="email"
                  type="text"
                  placeholder="mail@simmmple.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/60 dark:text-white focus:border-green-600"
                />
              </div>
              {/* Password */}
              <div className="mb-3">
                <label
                  htmlFor={77}
                  className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"
                >
                  {/* Password */}
                  كلمة السر
                </label>
                <input

                  id="password"
                  type="password"
                  placeholder="Min. 8 characters"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/60 dark:text-white focus:border-green-600"
                />
              </div>







              {/* Checkbox */}
              <div className="mb-4 flex items-center justify-between px-2">
                <div className="flex items-center">
                  <Checkbox color='green' onCheckboxChange={handleCheckboxChange} />

                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    {/* Keep me logged In */}
                    حفظ المعلومات
                  </p>
                </div>
                <a
                  className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  href=" "
                >
                  {/* Forgot Password?*/}
                  نسيت كلمة السر ؟
                </a>
              </div>
              <button className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                onClick={(handleSubmit)}
              >
                تسجيل الدخول
              </button>
              {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
            </div>
          </div>
        }
      </div>
    </>
  );
}
